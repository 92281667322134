import {sendFacebookEvent} from '../global/facebookApiCalls';

export function flagGYIM() {
  var threeFourFive = [3,4,5];
  var allSegments = [0,1,2,2.1,3,4,5];
  var allSegmentsNoDefault = [0,1,2,3,4,5];

  var isIm = false,
    centers = global.ccUserInfo.availableCenters,
    distanceFromSelectedCenter = centers[ global.ccUserInfo.centerID ].distance;

  if ( distanceFromSelectedCenter <= 50 ) {
    isIm = true;
  }

  if (typeof global.ccUserInfo.leadScore !== 'undefined') {
    global.dataLayer.push({ 'lead_score': global.ccUserInfo.leadScore });
    // __insp.push( [ 'tagSession', { lead_score: result.score } ] );
  }

  window['optimizely'] = window['optimizely'] || [];
  window['optimizely'].push({
    type: "event",
    eventName: "lead_prescreen_step",
    tags: {
      revenue: 0, // Optional in cents as integer (500 == $5.00)
      value: 0.00, // Optional as float
    },
  });

  sendFacebookEvent('Lead');
  // Push to data layer different market values (Google Tag Manager has correlating Tags/Events/Triggers/Variables/etc.)
  global.dataLayer.push({ 'in_market': isIm });
  global.dataLayer.push({ 'out_market': !isIm });
  if(isIm == true){
    global.dataLayer.push({ 'is_in_market':  'Y'});
  } else{
    global.dataLayer.push({ 'is_in_market':  'N'});
  }

  global.dataLayer.push({ 'leadcolor': global.ccUserInfo.leadScore });

  if ( global.ccUserInfo.leadScore === 'Yellow' || global.ccUserInfo.leadScore === 'Green' ) {
    global.ccUserInfo.isGreenYellow = true;
    if(allSegments.includes(global.ccUserInfo.leadSegment)){
      global.dataLayer.push({ 'event': 'GY05' });
      //sendFacebookEvent('nonptc');
    }
    if( isIm ) {
      global.dataLayer.push({ 'is_gyim': 'true' });
      global.dataLayer.push({ 'event': 'GYIM Received' });
      global.ccUserInfo.marketScore = 'GYIM';
      //sendFacebookEvent('GYIM');
    } else {
      global.dataLayer.push({ 'is_gyoom': 'true' });
      global.dataLayer.push({ 'event': 'GYOOM Received' });
      global.ccUserInfo.marketScore = 'GYOOM';
    }
    // Xandr pixel
    // Lead GY event
    $('head').append('<img src="https://secure.adnxs.com/px?id=1672080&seg=33202319&t=2" width="1" height="1">');
  } else {
    if(threeFourFive.includes(global.ccUserInfo.leadSegment)){
      global.dataLayer.push({ 'event': 'RB345' });
      //sendFacebookEvent('nonptc');
    }
    if( isIm ) {
      global.dataLayer.push({ 'is_rbim': 'true' });
      global.dataLayer.push({ 'event': 'RBIM Received' });
      global.ccUserInfo.marketScore = 'RBIM';
    } else {
      global.dataLayer.push({ 'is_rboom': 'true' });
      global.dataLayer.push({ 'event': 'RBOOM Received' });
      global.ccUserInfo.marketScore = 'RBOOM';
    }
    // Xandr pixel
    // Lead scored event
    $('head').append('<img src="https://secure.adnxs.com/px?id=1672078&seg=33202065&t=2" width="1" height="1">');
  }


if(allSegmentsNoDefault.includes(global.ccUserInfo.leadSegment)){
  switch(global.ccUserInfo.leadSegment) {
    case 0:
      global.dataLayer.push({
        'event': 'lead_segment_floodlight',
        'cat': 'clear001',
      });
      break;
    case 1:
      global.dataLayer.push({
        'event': 'lead_segment_floodlight',
        'cat': 'clear002',
      });
      break;
    case 2:
      global.dataLayer.push({
        'event': 'lead_segment_floodlight',
        'cat': 'clear003',
      });
      break;
    case 3:
      global.dataLayer.push({
        'event': 'lead_segment_floodlight',
        'cat': 'clear004',
      });
      break;
    case 4:
      global.dataLayer.push({
        'event': 'lead_segment_floodlight',
        'cat': 'clear005',
      });
      break;
    case 5:
      global.dataLayer.push({
        'event': 'lead_segment_floodlight',
        'cat': 'clear006',
      });
      break;
    default:
      //relax
    }
}

// add s20 counter logic
  switch(global.ccUserInfo.leadSegment20) {
    case 1:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads0',
      });
      break;
    case 2:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00',
      });
      break;
    case 3:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads000',
      });
      break;
    case 4:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads001',
      });
      break;
    case 5:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads002',
      });
      break;
    case 6:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads003',
      });
      break;
    case 7:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads004',
      });
      break;
    case 8:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads005',
      });
      break;
    case 9:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads006',
      });
      break;
    case 10:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads007',
      });
      break;
    case 11:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads008',
      });
      break;
    case 12:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads009',
      });
      break;
    case 13:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00-',
      });
      break;
    case 14:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00a',
      });
      break;
    case 15:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00b',
      });
      break;
    case 16:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00c',
      });
      break;
    case 17:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00d',
      });
      break;
    case 18:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00e',
      });
      break;
    case 19:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00f',
      });
      break;
    case 20:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00g',
      });
      break;
    default:
      global.dataLayer.push({
        'event': 'lead_segment20_floodlight',
        'cat': 'leads00h',
      });
  }


switch(global.ccUserInfo.leadScore) {
  case "Blue":
    global.dataLayer.push({
      'event': 'lead_score_floodlight',
      'cat': 'clear007',
    });
    break;
  case "Blue (No Hit)":
    global.dataLayer.push({
      'event': 'lead_score_floodlight',
      'cat': 'clear007',
    });
    break;
  case "Green":
    global.dataLayer.push({
      'event': 'lead_score_floodlight',
      'cat': 'clear008',
    });
    break;
  case "Red":
    global.dataLayer.push({
      'event': 'lead_score_floodlight',
      'cat': 'clear00-',
    });
    break;
  case "Yellow":
    global.dataLayer.push({
      'event': 'lead_score_floodlight',
      'cat': 'clear00a',
    });
    break;
  default:
    global.dataLayer.push({
      'event': 'lead_score_floodlight',
      'cat': 'clear009',
    });
}

 // add the iSpot pixel for a full lead
 var userId;
 var segment20;
 var channel;
 var campaignid;
 if( global.ccUserInfo.trans_id != undefined){
  campaignid = global.ccUserInfo.trans_id;
  if(campaignid  != ''){
    if((campaignid).toString().toLowerCase().includes('sem')){
      channel = "SEM";
    }else if((campaignid).toString().toLowerCase().includes('pmax') || (campaignid).toString().toLowerCase().includes('display')){
      channel = "Display";
    }else if((campaignid).toString().toLowerCase().includes('facebook')){
      channel = "Paid_Social";
    }else if((campaignid).toString().toLowerCase().includes('youtube') || (campaignid).toString().toLowerCase().includes('video')){
      channel = "Video";
    }
  }else{
    channel = "Organic_Direct";
  }
}else{
  channel = "Organic_Direct";
}

if( global.ccUserInfo.guid != undefined){
  userId = global.ccUserInfo.guid;
}

if(global.ccUserInfo.leadSegment20 <= 20 && global.ccUserInfo.leadSegment20 >=16){
  segment20 = "G1";
}else if(global.ccUserInfo.leadSegment20 <= 15 && global.ccUserInfo.leadSegment20 >= 11){
  segment20 = "G2";
}else if(global.ccUserInfo.leadSegment20 <= 10 && global.ccUserInfo.leadSegment20 >= 6){
  segment20 = "G3";
}else if(global.ccUserInfo.leadSegment20 <= 5 && global.ccUserInfo.leadSegment20 >= 1){
  segment20 = "G4";
}else{
  //relax
}

if(global.ccUserInfo.leadSegment20 <= 20 && global.ccUserInfo.leadSegment20 >=15){
  global.dataLayer.push({ 'lead_segment_20_tier': 'T1' });
  sendFacebookEvent('Tier1Leads');
}else if(global.ccUserInfo.leadSegment20 <= 14 && global.ccUserInfo.leadSegment20 >= 8){
  global.dataLayer.push({ 'lead_segment_20_tier': 'T2' });
  sendFacebookEvent('Tier2Leads');
}else{
  global.dataLayer.push({ 'lead_segment_20_tier': 'T3' });
  sendFacebookEvent('Tier3Leads');
}

if(userId != undefined && channel != undefined){
  $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=lead&customdata=${channel},${segment20}&refid=${userId}' style='display:none' alt='' />`);
}else if(channel != undefined){
  $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=lead&customdata=${channel},${segment20}' style='display:none' alt='' />`);
}else if(userId != undefined){
  $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=lead&refid=${userId}&customdata=${segment20}' style='display:none' alt='' />`);
}else{
  $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=lead&' style='display:none' alt='' />`);
}

}
/**
 @todo: add ajax call here to update the fake green ADI lead records back to their original color
 */
