import Cookie from 'js-cookie';
import {hideWaitingIcon,showWaitingIcon} from './w1_helper_functions';
import {is_w1, is_w2} from '../global/functions';
import {showMessageBox,hideMessageBox} from './formFunctions';
/* eslint-disable */
export function lookupCenter() {
  function getParam(p){
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
  let selectedCenter;
  let zip;

  if((getParam('Center__c') != "" || getParam('Center__c') != null) && (getParam('trans_id') == 'coloxfer')){
    selectedCenter = getParam('Center__c');
  }


  $('.form-locate-center-lookup .btn').addClass('disabled');
  $('.form-locate-center-lookup .btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Find Center');

  if((getParam('zipcode') != "" || getParam('zipcode') != null) && (getParam('trans_id') == 'coloxfer')){
    zip = parseInt(getParam('zipcode'), 10);
  }else{
    zip = $('#form-locate-center-address').val();
  }
  const zipRegex = /^\d{5}$/;

  if (!zipRegex.test(zip)) {
    $('.form-locate-center-lookup .alert').remove();
    $('.form-locate-center-lookup .row').before(`
      <div class="alert alert-danger mt-3 mb-3" role="alert">
        Please enter a valid ZIP Code.
      </div>
    `);

    return;
  }

  $.ajax({
    type: 'POST',
    url: ajaxurl,
    dataType: 'json',
    data: {
      action: 'cc_salesforce_getCenters',
      zipcode: zip,
      testing: global.ccUserInfo.testing,
      securityToken: global.securityToken,
    },
    success: function(msg) {
      hideWaitingIcon();
      $('.form-locate-center-lookup .btn').removeClass('disabled');
      $('.form-locate-center-lookup .btn').html('Find Center');
      if (msg.message !== 'No Centers Found') {
        $('.form-locate-center-lookup').hide();
        $('.form-locate-center > .row .text-muted').hide();
        $('.form-locate-center-results .list-group').empty();
        $('.form-locate-center-input').html(zip);
        global.ccUserInfo.zipcode = zip;

        let formLocations = [];

        var closedCentersInfo = [];
        var showClosedCentersButton = false;

        let clickToCallHTML = `
        <div class="center-info form-locate-center mobile-only" style="cursor: default; border: none !important;">
          <div class="row">
            <div class="col-sm-12" style="padding: 0;">
              <h2 class="h5 font-primary mobile-only" style="border: none !important; margin-bottom: -0.5em;">Speak with a Patient Advocate</h2>
            </div>
          </div>
          <p style="font-family: Inter; font-size: 16px; font-style: normal; font-weight: 400; margin: 0px;">Click below to speak with a Patient Advocate about your free consultation.</p>
          <div style="width: 100%;">
            <a class="click-to-call-test-button" href="tel:8882285041" style="background-color: #1e75b3;
            border: 1px solid #1e75b3;
            color: #fff;
            display: block;
            height: 38px;
            width: 100%;
            max-width: 420px;
            line-height: 38px;
            text-align: center;
            padding: 0;
            margin: 0 auto;
            cursor: pointer;
            transition: background-color .3s ease;">Call Now (888) 228-5041</a>
          </div>
          <h3 style="width: 100%; text-align: center; color: #1e75b3; font-size: 16px; margin-top: 1em; margin-bottom: -4em; font-weight: 800;">-Or-</h3>
        </div>
    `;
        var clickToCallCookie = Cookie.get("clickToCall")
        if(clickToCallCookie){
          $('.click-to-call-container').append(clickToCallHTML);
        }

        $.each(msg, function(index, center) {
          var centerClosedClass = "";
          var initClosedCentersPopup = false;

          if(center.centerClosed){
            centerClosedClass = "center-closed";
            closedCentersInfo.push(center);
            showClosedCentersButton = true;
          }

          pushDistanceToGTM( parseInt(center.distance, 10) );

          global.ccUserInfo.availableCenters[center.id] = center;
          if (center.id) {
            formLocations.push([center.name, center.Latitude, center.Longitude, center.address, center.address2, center.city, center.state, center.zipcode]);

            // RESKINNING FOR SCHEDULE NOW
            let centerHTML = window.location.pathname.includes('/schedule-now') ?
            `<div class="list-group-item list-group-item-action center-info schedule-center-info schedule-center-info-now `+centerClosedClass+`" id="${center.id}" data-center-id="`+center.id+`" distance="`+center.distance+`" isS20Active="`+center.isS20Active+`"  >
                <div class="center-info-distance center-info-text-now"><span class="w2-only">${center.city} – </span>${parseInt(center.distance, 10)} mi</div>
                <div class="center-info-name center-info-text-now w1-only">${center.city}</div>
                <div class="center-info-address center-info-text-now">
                  ${center.address} ${center.address2}<br />
                  ${center.city}, ${center.state}, ${center.zipcode}
                </div>
                <div class="center-info-select center-info-select-now w1-only"><span>Select</span></div>
              </div> `
            :
            `<div class="list-group-item list-group-item-action center-info schedule-center-info `+centerClosedClass+`" id="${center.id}" data-center-id="`+center.id+`" distance="`+center.distance+`" isS20Active="`+center.isS20Active+`"  >
              <div class="center-info-distance"><span class="w2-only">${center.city} – </span>${parseInt(center.distance, 10)} mi</div>
              <div class="center-info-name w1-only">${center.city}</div>
              <div class="center-info-address">
                ${center.address} ${center.address2}<br />
                ${center.city}, ${center.state}, ${center.zipcode}
              </div>
              <div class="center-info-select w1-only"><span>Select</span></div>
            </div>`;

            $('.form-locate-center-results .list-group').append(centerHTML);
            if(!((getParam('Center__c') != "" || getParam('Center__c') != null) && (getParam('trans_id') == 'coloxfer') == true)){
              $('.form-locate-center-results').removeClass('d-none');
            }
          }
        });



        // Mobile Click-to-Call schedule form Optimizely Test Set Up Jan24
        $('.click-to-call-test-button').one( "click", function() {
          window['optimizely'] = window['optimizely'] || [];
          window['optimizely'].push({
            type: "event",
            eventName: "MobileFormClick2Call",
          });
        });

        // todo: maybe don't init gmaps on w1, since it doesnt use it

        if(showClosedCentersButton){
          $('.center-closed').click(function(){
            var centerId = $(this).data('center-id');
            var centerPopupInfo;

            $.each(closedCentersInfo,function(index,centerInfo){
              if(centerId == centerInfo.id){
                centerPopupInfo = centerInfo;
              }
            });

            var closedCenterPopuphtml = "<p>The "+centerPopupInfo.city+" center has temporary limitations on scheduling in compliance with specific local or state guidelines. For more information, call <a href='tel:888-975-4943'>888-975-4943</a> or visit the center page for details. <a href='"+centerPopupInfo.centerUrl+"'>Learn more.</a></p>";

            $('#webform-component-messages .message-text').html(closedCenterPopuphtml);
            $('#webform-component-messages .buttons').find(".temp").remove();
            showMessageBox();
            $('#webform-component-messages').find(".buttons .dismiss").click(function(){
              hideMessageBox('#call-me-now-form');
            });

            $.when(global.saveLeadInfoLock).then(function(success){
              var centerClosedData = {};
              centerClosedData.GUID = global.ccUserInfo.contactID || global.ccUserInfo.guid;
              centerClosedData.isContact = ( global.ccUserInfo.contactID !== "" && global.ccUserInfo.contactID !== null ) ? true : false;
              centerClosedData.TransId = "covidschedptc";
              centerClosedData.securityToken = global.securityToken;
              centerClosedData.action = "cc_salesforce_submit_campaign_member";

              jQuery.ajax({
                url: ajaxurl,
                data: centerClosedData,
                cache: false,
                dataType: "json",
                type: "POST",
                // success: function( result, success ) {
                //   //console.log( 'Success: ', result );
                // },
                // error: function( result, error ) {
                //   //console.log( 'Error: ', result );
                // }
              });
            });
          });
        }

        const formMap = new google.maps.Map(document.getElementById('form-map'), {
          zoom: 3,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });

        let formMapBounds = new google.maps.LatLngBounds();
        let formMapInfoWindow = new google.maps.InfoWindow();
        let formMapMarker, i;

        for (i = 0; i < formLocations.length; i++) {
          formMapMarker = new google.maps.Marker({
            position: new google.maps.LatLng(formLocations[i][1], formLocations[i][2]),
            map: formMap,
          });

          formMapBounds.extend(formMapMarker.position);

          // google.maps.event.addListener(formMapMarker, 'click', (function(formMapMarker, i) {
          //   return function() {
          //     formMapInfoWindow.setContent(formLocations[i][0]);
          //     formMapInfoWindow.open(formMap, formMapMarker);
          //   }
          // })(formMapMarker, i));
        }

        formMap.fitBounds(formMapBounds);

        let formID = $('input[name="gform_submit"]').val();
        $('#gform_target_page_number_'+formID).val(3);
        $('.gform_next_button').attr('onclick', 'jQuery("#gform_target_page_number_'+formID+'").val("3");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');
        $('.gform_next_button').attr('onkeypress', 'jQuery("#gform_target_page_number_'+formID+'").val("3");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');

        $('.form-locate-center-results .list-group-item').each(function() {
          if (typeof selectedCenter !== 'undefined') {
            if ($(this).attr('id') == selectedCenter) {
              $(this).addClass('active');
              $('#gform_target_page_number_'+formID).val(3);
            }
          }

          $( "#" + $(this).attr('id') ).on( "click", function() {
            if(global.ccUserInfo.centerSelected != undefined){
              global.saveLeadInfoLock.resolve(true);
            }else{
              global.ccUserInfo.centerSelected = $("#" + $(this).attr('id') + " .center-info-name")[0].innerText;
            }
          } );

          if (global.ccUserInfo.centerID) {
            if ($(this).attr('id') == global.ccUserInfo.centerID) {
              $(this).addClass('active');
              $('#gform_target_page_number_'+formID).val(3);
            }
          }
          if(!$(this).hasClass('center-closed')){
            $(this).click(function() {
              showWaitingIcon('Retrieving Appointments');
              $('.form-locate-center-results .list-group span').removeClass('active');
              $(this).addClass('active');
              selectedCenter = $(this).attr('id');
              global.ccUserInfo.centerID = $(this).attr('id');
              var isS20Active = $(this).attr('isS20Active').toLowerCase() === "true";
              global.ccUserInfo.isS20Active = isS20Active;
              global.ccUserInfo.distanceToCenter = $(this).attr('distance');
              global.dataLayer.push({ 'centerID': $(this).attr('id')});
              $('#gform_target_page_number_'+ formID).val(3);
              //$('#gform_'+formID).trigger('submit', [true]);
              document.getElementById('gform_' + formID).submit();
              showWaitingIcon('Please Wait');
            });
          }
        });
        if((getParam('Center__c') != "" || getParam('Center__c') != null) && (getParam('trans_id') == 'coloxfer') == true){
          document.getElementById(selectedCenter).click();
        }
      } else {
        // No centers found
        console.log("No centers")
      }
    },
  });
}

/**
* Pushes the distance from center to the dataLayer
*
* @param  string   Pass in a string of the distance to calculate
*
* @return null
*/
function pushDistanceToGTM( distance ) {

  dataLayer.push({ 'distance_from_center_actual': distance });

  if( distance <= 25 ){
    dataLayer.push({ 'distance_from_center_group': '0-25' });
  }else if( distance > 25 && distance <= 50 ){
    dataLayer.push({ 'distance_from_center_group': '26-50' });
  }else if( distance > 50 && distance <= 75 ){
    dataLayer.push({ 'distance_from_center_group': '51-75' });
  }else if( distance > 75 && distance <= 100 ){
    dataLayer.push({ 'distance_from_center_group': '76-100' });
  }else if( distance > 100 && distance <= 200 ){
    dataLayer.push({ 'distance_from_center_group': '101-200' });
  }else {
    dataLayer.push({ 'distance_from_center_group': '200+' });
  }

  // Add/update distance to ccUserInfo
  ccUserInfo.distanceFromCenterActual = distance;

  //Add More granular tag. (e.g. 0-5, 6-10, )
  var segment = 5;
  var groupBottom = ( ( ( ( distance / 5 ) | 0 ) * 5 ) + 1 );
  var groupTop = groupBottom + 4;
  var groupString = groupBottom + '-' + groupTop;

  //Add a "cap"
  if( groupBottom > 200 ){
    groupString = '201+';
  }
  dataLayer.push({ 'distance_from_center_granular_group': groupString });
}
/* eslint-enable */
